.search-box{
    min-width: 400px;
    .ant-col-8 {
        min-width: 400px;
    }
    .ant-col-12 {
        min-width: 600px;
    }
    .ant-col-18 {
        min-width: 400px;
    }
    // .ant-col-20 {
    //     min-width: 1000px;
    // }
    .ant-col-24 {
        min-width: 400px;
    }
}

.list-add-box{
    margin: 20px 0;
}

.steps-box{
    max-width: 1000px;
    margin: 30px auto !important;
}

.red {
    color: red;
}

.failBox{
    margin: 20px 0;
    font-size: 16px;
}

.acount{
    position: relative;
    .acountCN{
        position: absolute;
        top: 22px;
        left: 0;
        // color: #edb321;
    }
}

.pay-detail{
    margin-left: 30px;
    font-size: 14px;
}
.detail-body {
    .ant-col-12, .ant-col-24{
        margin: 15px 0;
    }
    .ant-col-3,.ant-col-6 {
        text-align: right;
        min-width: 250px;
    }
}
.xing::before{
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}
.press-table{
    .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
        display: none;
    }
}

// 加保产品列表
.ins-list-box {
    height: 160px;
    background: #fff;
    padding: 25px 20 px;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #d8d8d8;
    border-radius: 15px;
    box-shadow: 0 2px 5px #d4d7d9;
    margin-top: 30px;
  
    .ins-list-title-box {
      .ins-list-title-left {
        width: 400px;
        height: 28px;
        line-height: 28px;
        background: #f99183;
        padding: 0 12px;
        border-bottom-right-radius: 24px;
        border-top-left-radius: 15px;
        margin-top: -1px;
  
        span {
          display: inline-block;
          font-size: 12px;
          color: white;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
  
        span:nth-child(1) {
          width: 38%;
        }
  
        span:nth-child(2) {
          width: 62%;
        }
      }
  
      .ins-list-title-right {
        text-align: center;
  
        span {
          display: inline-block;
          height: 28px;
          line-height: 28px;
          font-size: 14px;
          color: black;
          word-spacing: 20px;
          margin: 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
  
        span::before {
          content: '|';
          color: #1890ff;
          margin-right: 10px;
        }
  
        span:nth-child(1)::before {
          display: none;
        }
      }
    }
  
    .ins-list-contain-box {
  
      h3.ant-typography,
      .ant-typography h3 {
        font-size: 20px;
      }
  
      h4.ant-typography,
      .ant-typography h4 {
        font-size: 17px;
      }
  
      height: 132px;
  
      .ins-list-contain-left {
        height: 100%;
        text-align: center;
      }
  
      .ins-list-contain-center {
        height: 100%;
        text-align: center;
        display: list-item;
        list-style: none;
  
        .ins-list-color {
          color: #FF9933;
        }
  
        .ins-list-contain-remake {
          font-size: 16px;
          margin-top: 43px;
          text-align: center;
        }
      }
  
      .btn .ant-btn-primary {
        width: 130px;
        margin: 20px 0 0 30px;
      }
  
      .btn {
        .business-btn {
          background: #ff7440;
          border-color: #ff7440;
        }
      }
    }
  
    .ins-list-logo {
      width: 55px;
      height: 55px;
      display: inline-block;
      margin-bottom: 10px;
    }

    .heightFull {
      height: 100%;
    }

    .ins-btn{
      width: 130px;
      height: 36px;
      line-height: 36px;
      // background: #ff7440;
      border-radius: 4px;
      box-shadow: 0 2px 5px #d4d7d9;
      color: #fff;
      // border-color: #ff7440;
    }
  }
.mt22{
  margin-top: 22px;
}
.mt10{
  margin-top: 10px;
}

.insured-steps-box{
  max-width: 1200px;
  margin: 30px auto !important;
}

.pdfDeleteSpan{
  float: left;
  width: 160px;
  text-align: center;
  position: relative;
  display: inline-block;
  .pdfImg{
    width: 94px;
    margin-bottom: 10px;
    margin-top: -10px;
    margin-left: 14px;
  }
  .img-posi {
    position: absolute;
    top: -12px;
    right: 0;
    width: 30px;
    height: 30px;
  }
}
.file{
  margin: 5px;
  display: inline-block;
  >img{
      width: 100px;
      height: 124px;
  }
  .fileName{
      display: block;
      text-align: center;
  }
}

.pay-title{
  font-size: 16px;
  margin: 20px 0;
}